import useTranslator from '~/lib/useTranslator'

const sender = '- App Store Review'

export default function HomeAppstoreReviews() {
  const Translator = useTranslator()

  return (
    <div className="w-full bg-[url('/images/footer-bg.svg')] md:bg-contain bg-no-repeat pt-36 flex flex-col gap-8">
      <div className="flex flex-col gap-4 w-full max-w-screen-lg mx-auto text-center px-4 md:px-0">
        <h2 className="text-4xl md:text-5xl font-medium">
          {Translator.singular('Coaches, parents & players love Heja')}
        </h2>
        <p className="text-2xl">
          {Translator.singular(
            'Sports teams of all ages use Heja to organize, schedule and bring the team closer together. Here’s what they say:'
          )}
        </p>
      </div>

      <div className="flex flex-1 justify-center">
        <div className="flex flex-row items-center gap-2 border-4 rounded-full px-10 py-4">
          <svg className="h-6 w-28 flex items-center">
            <use xlinkHref="#totalstars" />
          </svg>
          <p className="flex md:text-xl font-medium">4.8 / 5 from +30K ratings</p>
        </div>
      </div>

      <div className="flex flex-col px-4 md:px-0 md:grid grid-cols-3 w-full max-w-screen-lg mx-auto gap-4 [&>div]:flex [&>div]:flex-col [&>div]:gap-4 [&>div]:p-4 [&>div]:bg-white [&>div]:rounded-xl [&>div]:shadow-sm [&>div]:items-center [&>div]:justify-center [&>div]:text-center [&>div>h4]:text-lg [&>div>h4]:font-light [&>div>h4]:text-center [&>div>p]:text-sm [&>div>p]:text-center">
        <div>
          <svg className="w-40 h-12">
            <use xlinkHref="#reviewstars" />
          </svg>
          <h4>
            “I’ve tried communicating with everything, but there was something missing.{' '}
            <strong>Heja rolls everything into one user friendly app</strong>.
          </h4>
          <p>{sender}</p>
        </div>
        <div>
          <svg className="w-40 h-12">
            <use xlinkHref="#reviewstars-alt" />
          </svg>
          <h4>
            “Coordination of games practices and team issues all together for everyone to access.
            Also <strong>love the reminders of games RSVP’d to in advance.</strong>”
          </h4>
          <p>{sender}</p>
        </div>
        <div>
          <svg className="w-40 h-12">
            <use xlinkHref="#reviewstars" />
          </svg>
          <h4>
            “Amazing app, great for managing my team practices, games and everything else,{' '}
            <strong>completely reduced spamming we had in facebook groups!</strong>”
          </h4>
          <p>{sender}</p>
        </div>

        <div>
          <svg className="w-40 h-12">
            <use xlinkHref="#reviewstars-alt" />
          </svg>
          <h4>
            “This app is a perfect solution for scheduling our weekly pick up games.{' '}
            <strong>
              The whole team likes it and quickly picked up on it due to the ease of use.
            </strong>
            ”
          </h4>
          <p>{sender}</p>
        </div>
        <div>
          <svg className="w-40 h-12">
            <use xlinkHref="#reviewstars" />
          </svg>
          <h4>
            “<strong>I simply love this app!</strong> <strong>Parents love it as well.</strong> I’ve
            given several similar apps trial runs, and{' '}
            <strong>this one outperforms the competition.</strong>”
          </h4>
          <p>{sender}</p>
        </div>
        <div>
          <svg className="w-40 h-12">
            <use xlinkHref="#reviewstars-alt" />
          </svg>
          <h4>
            “I manage 3 different sport teams and find this app invaluable. Easy to use and{' '}
            <strong>keeps everyone on the team up to date</strong> with info all in one place.”
          </h4>
          <p>{sender}</p>
        </div>
      </div>
    </div>
  )
}
