import { type MetaFunction } from '@remix-run/node'
import Footer from '~/components/Footer'
import GetStarted from '~/components/GetStarted'
import Navigation from '~/components/Navigation'
import HomeAppstoreReviews from '~/features/home/HomeAppstoreReviews'
import HomeFeatures from '~/features/home/HomeFeatures'
import HomeHeader from '~/features/home/HomeHeader'
import { mergeMeta } from '~/utils/mergeMeta'

export const meta: MetaFunction = mergeMeta(
  () => [
    { title: 'Heja | Sports team management and communication app' },
    {
      name: 'description',
      content:
        'Free app for your sports team. The only app you need to easily handle communication between coaches, managers, parents and players. Management, scheduling, availability, messaging, roster.',
    },
  ],
  () => [{ name: 'apple-itunes-app', content: 'app-id=1157335714' }]
)

export default function Index() {
  return (
    <>
      <Navigation />
      <HomeHeader />
      <HomeFeatures />
      <HomeAppstoreReviews />
      <section className="w-full max-w-screen-lg mx-auto mt-6 px-4 md:px-0 md:mt-24">
        <GetStarted />
      </section>
      <Footer />
    </>
  )
}
