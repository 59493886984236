import DownloadButtons from '~/components/DownloadButtons'
import useLanguage from '~/contexts/language/useLanguage'
import useTranslator from '~/lib/useTranslator'

export default function HomeFeatures() {
  const Translator = useTranslator()
  const lang = useLanguage()

  return (
    <>
      <section className="bg-white py-10 px-4">
        <div className="w-full max-w-screen-lg mx-auto flex flex-col gap-8 md:gap-32 md:flex-row">
          <div className="max-w-[400px]">
            <img src={`/images/${lang}/schedule.png`} width={570} height={545} alt="Schedule" />
          </div>

          <div className="flex flex-col gap-4 justify-center text-center md:text-left">
            <h3 className="text-4xl md:text-5xl md:leading-snug font-medium">
              {Translator.singular('Keep your team on schedule')}
            </h3>
            <p>
              {Translator.singular(
                'Easily plan upcoming practices, games and team activities for all to see. Get updated on who’s available to play. Heja helps out by reminding everyone to reply!'
              )}
            </p>

            <aside className="border-t pt-4 flex flex-row gap-6 text-left">
              <div className="flex-1 font-normal">
                {Translator.singular(
                  '”Heja has given me back 2 hours/week to coach that I used to spend emailing, phoning, and calling athletes and parents.”'
                )}
              </div>
              <div className="flex flex-1 flex-col">
                <span className="font-semibold">Maureen Griffin</span>
                San Francisco Fencing coach
              </div>
            </aside>
          </div>
        </div>
      </section>

      <section className="bg-white px-4">
        <div className="w-full max-w-screen-xl rounded-xl md:rounded-3xl bg-gray-100 mx-auto">
          <div className="w-full max-w-screen-lg mx-auto flex flex-col md:flex-row-reverse gap-8 md:gap-0">
            <div className="flex-1 justify-end items-end p-4">
              <img
                src={`/images/${lang}/conversation.png`}
                width={480}
                height={490}
                alt="Messaging"
              />
            </div>

            <div className="flex flex-1 flex-col gap-4 justify-center text-center md:text-left px-4">
              <h3 className="text-4xl md:text-5xl md:leading-snug font-medium">
                {Translator.singular('Everything doesn’t have to go through the coach')}
              </h3>
              <p>
                {Translator.singular(
                  'Team messaging, coach communication, organizing carpools and much more. Free up time by helping parents and players start communicating.'
                )}
              </p>

              <aside className="border-t pt-4 flex flex-row gap-6 text-left mb-4">
                <div className="flex-1 gap-2 flex flex-col">
                  <p className="font-normal">
                    &ldquo;I love being able to see who viewed all posts and the schedule. Easier to
                    follow up and makes communication much easier!&ldquo;
                  </p>
                  <p>
                    <span className="font-semibold">Sammilynno Stewart</span>, California coach.
                  </p>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-10 px-4">
        <div className="w-full max-w-screen-lg mx-auto flex flex-col gap-8 md:gap-16 md:flex-row">
          <div className="flex max-w-[400px]">
            <img src={`/images/${lang}/homefeature.png`} width={570} height={583} alt="Features" />
          </div>

          <div className="flex flex-1 flex-col gap-4 justify-center text-center md:text-left">
            <h3 className="text-4xl md:text-5xl md:leading-snug font-medium">
              {Translator.singular('A place to call home')}
            </h3>
            <p>
              {Translator.singular(
                'Forget the clutter of emails, texts and phone calls. Heja brings your whole team together in one app, with useful info at everyone’s fingertips.'
              )}
            </p>

            <aside className="border-t pt-8 mt-4 flex flex-col gap-6 text-left">
              <div className="flex items-center gap-4 flex-1">
                <img
                  src="/images/schedule-icon.svg"
                  width={38}
                  height={38}
                  className="w-10 h-10"
                  alt="Schedule"
                />
                <p className="font-normal">
                  {Translator.singular('We sync the team schedule to your personal calendar')}
                </p>
              </div>
              <div className="flex items-center gap-4 flex-1">
                <img
                  src="/images/privacy-icon.svg"
                  width={38}
                  height={38}
                  className="w-10 h-10"
                  alt="Privacy icon"
                />
                <p className="font-normal">
                  {Translator.singular(
                    'Teams are only accessed by team members and it’s up to them what info they share and with whom'
                  )}
                </p>
              </div>
            </aside>
          </div>
        </div>
      </section>
      <section className="bg-white px-4 pb-8 md:hidden">
        <div className="flex w-full max-w-screen-lg mx-auto flex-1 justify-center border-t pt-8">
          <DownloadButtons />
        </div>
      </section>
    </>
  )
}
